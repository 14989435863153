@font-face {
  font-family: proxima-nova;
  src: url('./fonts/proxima-nova/proximanova-light-webfont.ttf') format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: proxima-nova;
  src: url('./fonts/proxima-nova/proximanova-regular-webfont.ttf') format("truetype");
  font-weight: 400;
  font-style: normal
}

@font-face {
  font-family: proxima-nova;
  src: url('./fonts/proxima-nova/proximanova-semibold-webfont.ttf') format("truetype");
  font-weight: 600;
  font-style: normal
}

@font-face {
  font-family: proxima-nova;
  src: url('./fonts/proxima-nova/proximanova-bold-webfont.ttf') format("truetype");
  font-weight: 700;
  font-style: normal
}


/*Global overrides*/
html, body {
  font-family: proxima-nova, Proxima Nova ,Lucida Grande,Segoe UI,Segoe,Lucida Sans,Lucida Sans Unicode,Verdana,sans-serif !important;
  background-color: #F1F7FD !important;
  font-size: 14px !important;
  font-weight: 300 !important;
  color: #425563 !important;
}

body [class^='mat-'], body [class*=' mat-'] {
  font-family: proxima-nova, Proxima Nova ,Lucida Grande,Segoe UI,Segoe,Lucida Sans,Lucida Sans Unicode,Verdana,sans-serif !important;
} 

body .material-icons {
  font-family: 'Material Icons';
}

h1 {
  font-family: proxima-nova, Proxima Nova ,Lucida Grande,Segoe UI,Segoe,Lucida Sans,Lucida Sans Unicode,Verdana,sans-serif !important;
  font-size: 24px !important;
  font-weight: 600 !important;
}

.MuiButtonBase-root {
  background-color: #00C389 !important;
  color: #fff !important;
  border-radius: 30px !important;
  height: 48px;
  min-width: 132px !important;
  font-size: 16px !important;
  font-weight: 700 !important;
}

.MuiContainer-maxWidthXs {
  max-width: 400px !important;
}

.MuiInputBase-root {
  background-color: #fff;
  border: 0px !important;
}

.MuiInputBase-root {
  background-color: #fff;
  outline: none !important;
}

.MuiButton-root {
  text-transform: none !important;
}

.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline,
.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline:hover,
.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline,
.MuiOutlinedInput-input,
.MuiOutlinedInput-input:hover,
.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input,
.MuiInputLabel-outlined,
.MuiInputLabel-outlined:hover,
.MuiInputLabel-outlined.Mui-focused {
  border: none !important;
}

.MuiButton-label {
  line-height: unset !important;
}